import React from "react";
import "../styles/styles.css";

const Footer = () => {
  return (
    <nav>
      <div id="copy">&copy; </div>{" "}
      <a id="web" href="www.onis-emem.com">
        {" "}
        Onis Emem
      </a>{" "}
    </nav>
  );
};

export default Footer;
